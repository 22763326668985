/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from "react";
import propTypes from "prop-types";

import OneTab from "components/common/dashboard/oneTab";
import ElementsModal from "components/common/modals/elementsModal";
import { AnimatePresence, motion } from "framer-motion";

import ElementIcon from "assets/svgs/element.svg";
import { OneElement } from "components/dashboard/createPrism/two/mainContent";
import { IoClose } from "react-icons/io5";
import { useGetTemplateSkillsQuery } from "services/api/baseApiSlice";
import Loading from "global/spinner/loading";
// import { rearrangeSkillGroups } from "helpers/main";

export default function TalentDimensions({ close }) {
  const [skillGroups, setSkillGroups] = useState(undefined);
  const { data: allSkills, isFetching: fetchingSkills } =
    useGetTemplateSkillsQuery();

  // set the state of the store - AFTER fetching data
  useEffect(() => {
    // skillGroups - setKnackGroups
    if (allSkills) {
      setSkillGroups(allSkills);
    }
  }, [allSkills]);

  const [activeKnacks, setActiveKnacks] = useState(null);
  const [activeGrp, setActiveGrp] = useState("Cognitive");

  // set active group knacks
  useEffect(() => {
    if (skillGroups?.length > 0 && activeGrp !== "templates") {
      const arrangedKnacks = skillGroups;
      setActiveKnacks(
        arrangedKnacks?.filter((grp) => grp.name === activeGrp)[0]?.knacks,
      );
    }
  }, [skillGroups, activeGrp]);

  useEffect(() => {
    // add style overflow hidden to body
    document.body.style.overflow = "hidden";
    // remove style overflow hidden to body on unmount
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const [showElementModal, setShowElementModal] = useState(false);
  const [activeElement, setActiveElement] = useState(null);

  const showElementsModal = (val) => {
    setActiveElement(val);
    setShowElementModal(true);
  };

  return (
    <div
      className="fixed inset-0 z-[130] flex h-screen w-screen flex-col items-center justify-center bg-black/50 backdrop-blur-sm"
      onClick={(e) => e.target === e.currentTarget && close()}
    >
      <motion.div
        initial={{ y: 20 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 20, opacity: 0 }}
        transition={{ duration: 0.7, ease: "easeIn" }}
        className="relative flex h-[85%] w-[90%] rounded-[10px] bg-white"
      >
        <div
          onClick={() => close()}
          className="absolute -top-12 right-0 flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full bg-white/90 md:-right-12 md:-top-1"
        >
          <IoClose className="" color="#2B2A2A" size={25} />
        </div>
        {allSkills && (
          <div className="bg-left-dimensions no-scrollbar flex h-full w-[10%] min-w-[100px] flex-col overflow-y-auto overflow-x-hidden">
            {allSkills?.map((grp, index) => (
              <OneTab
                active={activeGrp}
                name={grp?.name}
                isActive={activeGrp === grp?.name}
                setActive={() => setActiveGrp(grp?.name)}
                index={grp?.name}
                key={index}
                fromDimensions
              />
            ))}
          </div>
        )}
        {(activeKnacks === null || fetchingSkills || !allSkills) && <Loading />}
        {activeKnacks && !fetchingSkills && (
          <div className="m-auto flex h-max max-h-full w-[90%] flex-wrap items-center justify-center gap-8 overflow-y-auto px-32 py-16">
            {activeKnacks?.map((element) => (
              <OneElement
                key={element?._id}
                fromDimensions
                element={element}
                showElementsModal={() => showElementsModal(element)}
              />
            ))}
            {activeKnacks?.length === 0 && (
              <h1 className="font-semibold text-[#2b2a2a]">
                Knacks for <span className="font-bold">{activeGrp}</span> coming
                soon
              </h1>
            )}
          </div>
        )}
      </motion.div>
      <AnimatePresence>
        {showElementModal && (
          <ElementsModal
            element={activeElement.name}
            texts={activeElement.definition}
            close={() => setShowElementModal(false)}
            iconSvg={ElementIcon}
          />
        )}
      </AnimatePresence>
    </div>
  );
}

TalentDimensions.propTypes = {
  close: propTypes.func.isRequired,
};
