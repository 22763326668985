import { Karma } from "next/font/google";
import Image from "next/image";
import React from "react";
import { motion } from "framer-motion";
import { IoClose } from "react-icons/io5";

const font = Karma({
  weight: ["400", "600", "700"],
  subsets: ["latin"],
});

const FormModal = ({
  showModal,
  closeModal,
}: {
  showModal: boolean;
  closeModal: () => void;
}) => {
  React.useEffect(() => {
    const handleEscape = (event: any) => {
      if (event.keyCode === 27) {
        closeModal();
      }
    };
    document.addEventListener("keydown", handleEscape);
    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!showModal) return null;

  return (
    <div
      className="fixed inset-0 z-[130] flex h-full w-full items-center justify-center bg-black/30 backdrop-blur-sm"
      id="backdrop"
      onClick={(e) => (e.target === e.currentTarget ? closeModal() : null)}
    >
      <motion.div
        initial={{ scale: 0.8, opacity: 0.7 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.5, opacity: 0, transition: { duration: 0.5 } }}
        transition={{ duration: 0.3, ease: [0.25, 0.1, 0.25, 1.0] }}
        className="relative z-50 flex h-[80vh] w-[80vw] rounded-lg bg-white p-5 md:h-[90vh]"
      >
        <div
          onClick={() => {
            closeModal();
          }}
          className="-md:right-12 absolute -top-12 right-0 z-50 flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full bg-gray-500 md:-top-12"
        >
          <IoClose className="" color="#fff" size={25} />
        </div>
        <div className="flex w-full flex-col space-y-4 rounded-l-lg bg-[#F6F6F6] p-5 md:w-[40%]">
          <div className="flex basis-[30%] flex-col justify-between">
            <div className="flex flex-col">
              <span
                className={`text-2xl font-semibold text-[#242424] ${font.className}`}
              >
                Get in touch
              </span>
              <span className="text-sm text-[#5A5A5A]">
                Contact us via the emails below with any question
              </span>
            </div>
          </div>
          <div className="flex max-h-[500px] basis-[90%] flex-col justify-evenly gap-4 rounded-l-lg">
            <div className="flex flex-col rounded-lg border-2 border-white bg-white p-4 hover:border-2 hover:border-[#824AFF]">
              <span className="pb-4 font-[500]">Support</span>
              <a
                href="mailto:help@knackapp.com"
                className="tm:text-lg text-sm font-[500] text-[#0038FF] underline underline-offset-4"
              >
                help@knackapp.com
              </a>
            </div>
            <div className="flex flex-col rounded-lg border-2 border-white bg-white p-4  hover:border-2 hover:border-[#824AFF]">
              <span className="pb-4 font-[500]">Business</span>
              <a
                href="mailto:business@knackapp.com"
                className="tm:text-lg text-sm font-[500] text-[#0038FF] underline underline-offset-4"
              >
                business@knackapp.com
              </a>
            </div>
            <div className="flex flex-col rounded-lg border-2 border-white bg-white p-4  hover:border-2 hover:border-[#824AFF]">
              <span className="pb-4 font-[500]">Press</span>
              <a
                href="mailto:press@knackapp.com"
                className="tm:text-lg text-sm font-[500] text-[#0038FF] underline underline-offset-4"
              >
                press@knackapp.com
              </a>
            </div>
          </div>
        </div>
        <div className={`relative hidden w-[60%] rounded-r-lg md:flex`}>
          <Image
            src={
              "https://res.cloudinary.com/knackapp/image/upload/v1680192313/static/new_landing/79be252a68e546c31278a70540de8e14.png"
            }
            fill
            alt="student picture"
            className="rounded-r-lg object-cover"
          />
        </div>
      </motion.div>
    </div>
  );
};

export default FormModal;
