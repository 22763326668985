import Image from "next/image";
import React, { useState, useEffect } from "react";
import propTypes from "prop-types";

import TemplatesIcon from "assets/svgs/templates.svg";
import TemplatesActiveIcon from "assets/svgs/templatesActive.svg";
import SoftSkillsIcon from "assets/svgs/softSkills.svg";
import SoftSkillsActiveIcon from "assets/svgs/softSkillsActive.svg";
import LifeSkillsIcon from "assets/svgs/lifeSkills.svg";
import LifeSkillsActiveIcon from "assets/svgs/lifeSkillsActive.svg";
import CollaborationSkills from "assets/svgs/collaborationSkills.svg";
import CollaborationSkillsActive from "assets/svgs/collaborationSkillsActive.svg";
import LeadershipSkills from "assets/svgs/leadershipSkills.svg";
import LeadershipSkillsActive from "assets/svgs/leadershipSkillsActive.svg";
import InnovationSkills from "assets/svgs/innovationSkills.svg";
import InnovationSkillsActive from "assets/svgs/innovationSkillsActive.svg";
import CollegeMajors from "assets/svgs/collegeMajors.svg";
import CollegeMajorsActive from "assets/svgs/collegeMajorsActive.svg";
import VocationalPathways from "assets/svgs/vocationalPathways.svg";
import VocationalPathwaysActive from "assets/svgs/vocationalPathwaysActive.svg";
import Careers from "assets/svgs/careers.svg";
import CareersActive from "assets/svgs/careersActive.svg";
import Social from "assets/svgs/social.svg";
import SocialActive from "assets/svgs/socialActive.svg";
import Creative from "assets/svgs/creative.svg";
import CreativeActive from "assets/svgs/creativeActive.svg";

export default function OneTab({ name, setActive, isActive, fromDimensions }) {
  const [activeIcons, setActiveIcons] = useState([]);
  // switch case for icons
  const icon = (i) => {
    switch (i) {
      case "templates":
        return [TemplatesIcon, TemplatesActiveIcon];
      case "Thinking Skills":
        return [SoftSkillsIcon, SoftSkillsActiveIcon];
      case "Personality":
        return [LifeSkillsIcon, LifeSkillsActiveIcon];
      case "Teamwork Skills":
        return [CollaborationSkills, CollaborationSkillsActive];
      case "Leadership Skills":
        return [LeadershipSkills, LeadershipSkillsActive];
      case "Innovation Skills":
        return [InnovationSkills, InnovationSkillsActive];
      case "Academic Domains":
        return [CollegeMajors, CollegeMajorsActive];
      case "Learning Domains":
        return [VocationalPathways, VocationalPathwaysActive];
      case "Social Skills":
        return [Social, SocialActive];
      case "Creative Skills":
        return [Creative, CreativeActive];
      case "Careers":
        return [Careers, CareersActive];
      default:
        return [TemplatesIcon, TemplatesActiveIcon];
    }
  };

  useEffect(() => {
    if (activeIcons.length === 0) {
      setActiveIcons(icon(name));
    }
  }, [name]);

  if (activeIcons.length === 0 || !name) return null;

  return (
    <div
      onClick={() => setActive()}
      className={`py-4 ${
        !isActive
          ? fromDimensions
            ? ""
            : "bg-[#232323]"
          : fromDimensions
          ? ""
          : "z-20 bg-[#191919]"
      } group relative flex cursor-pointer flex-col items-center justify-center gap-2`}
    >
      <Image
        src={!isActive ? activeIcons[0] : activeIcons[1]}
        height={fromDimensions ? 20 : 30}
        width={fromDimensions ? 20 : 30}
        alt={name}
        style={{
          maxWidth: "100%",
          height: "auto",
          objectFit: "contain",
        }}
      />
      <span
        className={`text-center ${
          fromDimensions ? "text-[12px]" : "text-[14px]"
        } font-medium ${
          isActive ? "text-[#6487FF]" : "font-semibold text-[#A6A6A6]"
        } px-2 transition-all duration-300 ease-in group-hover:text-[#6487FF]/90`}
      >
        {name}
      </span>
    </div>
  );
}

OneTab.propTypes = {
  name: propTypes.string.isRequired,
  setActive: propTypes.func.isRequired,
  isActive: propTypes.bool.isRequired,
  index: propTypes.number.isRequired,
  active: propTypes.number.isRequired,
  fromDimensions: propTypes.bool,
};
