/* eslint-disable no-useless-escape */

const validEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

const validUsername = (username) => /^[^\s]{3,15}$/.test(username);

const validPassword = (password) =>
  /(?=(.*[0-9]))(?=.*[\!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,16}$/.test(
    password
  );

const validPhoneNumber = (phoneNumber) =>
  /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(phoneNumber);

const validName = (name) =>
  /^[A-Za-z]+(([,.] |[ '-])[A-Za-z]+)*([.,'-]?){2,30}$/.test(name);

export {
  validEmail,
  validUsername,
  validPassword,
  validPhoneNumber,
  validName,
};

export const trimSpace = (value) => value.replace(/\s/g, "");
